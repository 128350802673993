@import 'fonts.css';
@import 'basic.css';

/* Body */
body {
	background: var(--body-bg) url("icons/contao.svg") center 4.5em no-repeat;
}

/* Container */
#container {
	margin: 15em 10px 0;
}

#main {
	max-width: 350px;
	margin: 0 auto;
	background: var(--content-bg);
	border-radius: 2px;
}

/* Headlines */
#container h1 {
	margin-bottom: 1em;
}

/* JS warning */
#javascript {
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding: 9px;
	background: var(--white);
	text-align: center;
}

.js #javascript {
	display: none;
}

/* Login form */
.formbody {
	padding: 45px;
}

.widget {
	margin: 0;
}

.tl_text {
	margin: 2px 0;
	padding-right: 24px;
}

.widget-checkbox {
	margin-top: 6px;
}

.tl_checkbox + label {
	margin-left: 4px;
}

.submit_container {
	margin-top: 12px;
}

.submit_container a {
	float: right;
	margin-top: 8px;
}

.submit_container a:hover {
	color: var(--contao);
}

label[for="username"] {
	position: absolute;
	top: 9px;
	right: 6px;
	background: url("icons/person.svg") left top no-repeat;
	background-size: 16px;
	width: 16px;
	height: 16px;
	text-indent: -999em;
	z-index: 1;
}

label[for="password"], label[for="password_confirm"] {
	position: absolute;
	top: 10px;
	right: 7px;
	background: url("icons/lock-locked.svg") left top no-repeat;
	background-size: 14px;
	width: 14px;
	height: 14px;
	text-indent: -999em;
	z-index: 1;
}

.widget-password.wizard label[for="password"] {
	right: 31px;
}

label[for="verify"] {
	position: absolute;
	top: 9px;
	right: 6px;
	background: url("icons/shield.svg") left top no-repeat;
	background-size: 16px;
	width: 16px;
	height: 16px;
	text-indent: -999em;
	z-index: 1;
}

.qr_code {
	margin: 0 0 16px;
}

.qr_code .tl_help {
	line-height: 1.3;
}

/* Error messages */
.tl_message {
	max-width: 420px;
	margin: 36px auto 0;
	text-align: center;
}

.tl_error, .tl_info, .tl_confirm {
	padding: 3px 0;
	line-height: 1.5;
	font-size: 1rem;
}

.tl_error {
	color: var(--red);
}

.tl_info {
	color: var(--blue);
}

.tl_confirm {
	color: var(--green);
}
