/* Appearance */
:root {
	--text: #222;
	--body-bg: #eaeaec;
	--content-bg: #fff;
	--content-border: #cacacc;
	--black: #000;
	--white: #fff;
	--gray: #999;
	--green: #589b0e;
	--red: #c33;
	--blue: #006494;
	--orange: #f90;
	--contao: #f47c00;
	--border: #eaeaec;
	--nav: #d3d6da;
	--nav-hover: #eaedf1;
	--nav-bg: #0f1c26;
	--nav-hover-bg: #eaedf1;
	--nav-current: #172b3b;
	--nav-group: #9fa4a8;
	--nav-separator: #3a454d;
	--hover-row: #fffce1;
	--header-bg: #f47c00;
	--header-bg-hover: #e67300;
	--header-text: #fff;
	--invert-bg: #333;
	--invert-text: #fff;
	--table-header: #f7f7f8;
	--table-odd: #fff;
	--table-even: #fbfbfc;
	--table-nb-header: #f2f2f3;
	--table-nb-odd: #fff;
	--table-nb-even: #f7f7f8;
	--panel-bg: #f3f3f5;
	--tree-header: #f3f3f5;
	--tree-header-border: #dddddf;
	--form-text-disabled: #bbb;
	--form-border: #aaa;
	--form-border-disabled: #c8c8c8;
	--form-bg: #fff;
	--form-bg-hover: #f6f6f6;
	--form-bg-disabled: #f9f9f9;
	--form-button: #eee;
	--form-button-hover: #f6f6f6;
	--form-button-active: #aaa;
	--form-button-disabled: #e9e9e9;
	--diff-left: #ffe8e5;
	--diff-del: #ffc1bf;
	--diff-right: #e0ffe8;
	--diff-ins: #abf2bc;
	--code-bg: #f0f0f0;
	--checkerbox-bg: #ddd;
	--info: #808080;
	--active-bg: #fffce1;
	--active-border: #e7b36a;
	--pre-disabled: #a6a6a6;
	--error-bg: rgba(204,51,51,.15);
	--confirm-bg: rgba(88,155,14,.15);
	--info-bg: rgba(0,100,148,.15);
	--new-bg: rgba(224,149,21,.15);
	--progress-running: #f47c00;
	--progress-finished: #589b0e;
	--drag-bg: #a3c2db;
	--legend: #6a6a6c;
	--paste-hint: #838990;
	--serp-preview: #3c4043;
	--serp-preview-title: #1a0dab;
	--nested-bg: #fbfbfd;
}

html[data-color-scheme="dark"] {
	color-scheme: dark;
	--text: #ddd;
	--body-bg: #121416;
	--content-bg: #1b1d21;
	--content-border: #414448;
	--black: #fff;
	--white: #000;
	--blue: #0073a8;
	--orange: #d68c23;
	--contao: #f47c00;
	--border: #303236;
	--nav-bg: #1b1d21;
	--nav-hover-bg: #1b325f;
	--nav-current: #272a30;
	--nav-separator: #3f3f3f;
	--hover-row: #1b325f;
	--header-bg: #292c32;
	--header-bg-hover: #202327;
	--header-text: #ddd;
	--invert-bg: #8f96a3;
	--invert-text: #222;
	--table-header: #232529;
	--table-odd: #1b1d21;
	--table-even: #1e2024;
	--table-nb-header: #292c32;
	--table-nb-odd: #1b1d21;
	--table-nb-even: #23252a;
	--panel-bg: #272a30;
	--tree-header: #272a30;
	--tree-header-border: #3f4146;
	--form-text-disabled: #666;
	--form-border: #44464b;
	--form-border-disabled: #3a3c40;
	--form-bg: #151619;
	--form-bg-hover: #1e2024;
	--form-bg-disabled: #1e2024;
	--form-button: #31333a;
	--form-button-hover: #383a42;
	--form-button-active: #777;
	--form-button-disabled: #26272c;
	--diff-left: rgba(248,81,73,.17);
	--diff-del: rgba(248,81,73,.4);
	--diff-right: rgba(46,160,67,.17);
	--diff-ins: rgba(46,160,67,.4);
	--code-bg: #30343b;
	--checkerbox-bg: #30343b;
	--info: #9095a2;
	--active-bg: #1b325f;
	--active-border: #264787;
	--drag-bg: #1b325f;
	--legend: #747b8b;
	--serp-preview: #bdc1c6;
	--serp-preview-title: #8ab4f8;
	--nested-bg: #1e2024;
}

html[data-color-scheme="dark"] .color-scheme--light, .color-scheme--dark {
	display: none;
}

html[data-color-scheme="dark"] .color-scheme--dark, .color-scheme--light {
	display: initial;
}

/* HTML */
html {
	font-size: 100%;
	-webkit-text-size-adjust: 100%;
}

/* General */
header, footer, nav, section, aside, main, article, figure, figcaption {
	display: block;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl {
	margin: 0;
}

img {
	border: 0;
}

table {
	border-spacing: 0;
	border-collapse: collapse;
	empty-cells: show;
}

th, td {
	text-align: left;
}

input, select, label, img, a.tl_submit, .tl_select {
	vertical-align: middle;
}

button {
	cursor: pointer;
}

button[disabled] {
	cursor: default;
}

nav ul, nav li {
	margin: 0;
	padding: 0;
	list-style: none;
}


/* Font */
body {
	font-family: -apple-system, system-ui, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	font-weight: 400;
	font-size: .875rem;
	line-height: 1;
	color: var(--text);
}

h1, h2, h3, h4, h5, h6, strong, b, th {
	font-weight: 600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	body {
		font-weight: 300;
	}

	h1, h2, h3, h4, h5, h6, strong, b, th {
		font-weight: 500;
	}
}

pre, code, .tl_textarea.monospace {
	font: 300 .75rem/1.25 SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 1rem;
}

input, textarea, select, button {
	font: inherit;
	color: inherit;
	line-height: inherit;
}

input, select {
	line-height: 17px; /* see #501 and #79 */
}

@supports (display:-ms-grid) {
	input, select {
		line-height: 1.1;
	}
}

.tl_gray {
	color: var(--gray);
}

.tl_green {
	color: var(--green);
}

.tl_red {
	color: var(--red);
}

.tl_blue {
	color: var(--blue);
}

.tl_orange {
	color: var(--orange);
}

span.mandatory {
	color: var(--red);
}

.upper {
	text-transform: uppercase;
}

/* Basic elements */
a {
	color: var(--text);
	text-decoration: none;
}

a:hover, a:active {
	color: var(--contao);
}

hr {
	height: 1px;
	margin: 18px 0;
	border: 0;
	background: var(--border);
	color: var(--border);
}

p {
	margin-bottom: 1em;
	padding: 0;
}

.hidden {
	display: none !important;
}

.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
}

/* Tables */
table.with-border th, table.with-border td {
	border: solid var(--border);
	border-width: 1px 0;
}

table.with-border th {
	background-color: var(--table-header);
}

table.with-padding th, table.with-padding td {
	padding: 6px;
}

table.with-zebra th {
	background-color: var(--table-nb-header);
}

table.with-zebra tbody tr:nth-child(odd) td {
	background-color: var(--table-nb-odd);
}

table.with-zebra tbody tr:nth-child(even) td {
	background-color: var(--table-nb-even);
}

/* Invisible elements */
.clear {
	clear: both;
	height: 0.1px;
	line-height: 0.1px;
	font-size: 0.1px;
}

.cf:before, .cf:after {
	content: " ";
	display: table;
}

.cf:after {
	clear: both;
}

.invisible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

/* Widgets */
.widget {
	margin-left: 15px;
	margin-right: 15px;
	position: relative;
}

.widget {
	font-size: 0;
}

.widget * {
	font-size: .875rem;
}

.widget > div {
	font-size: 0;
}

.widget > div > * {
	font-size: .875rem;
}

.widget pre, .widget code {
	font-size: .7rem;
}

.widget h3 {
	min-height: 16px;
}

.widget h3 img {
	margin-right: 3px;
}

.widget legend {
	padding: 0;
}

.widget legend img {
	vertical-align: -1px;
}

.widget-captcha {
	display: initial !important;
}

.widget p.info {
	margin: 2px 0;
	padding: 7px;
	background: var(--panel-bg);
	line-height: 1.3;
	border-radius: 3px;
}

.widget picture {
	display: contents;
}

/* Forms */
optgroup {
	padding-top: 3px;
	padding-bottom: 3px;
	font-style: normal;
	background: var(--form-bg);
}

fieldset.tl_checkbox_container, fieldset.tl_radio_container {
	border: 0;
	margin: 15px 0 1px;
	padding: 0;
}

fieldset.tl_checkbox_container {
	line-height: 15px;
}

fieldset.tl_radio_container {
	line-height: 16px;
}

fieldset.tl_checkbox_container legend, fieldset.tl_radio_container legend {
	font-weight: 600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	fieldset.tl_checkbox_container legend, fieldset.tl_radio_container legend {
		font-weight: 500;
	}
}

fieldset.tl_checkbox_container .check-all {
	color: var(--gray);
}

fieldset.tl_checkbox_container button {
	vertical-align: middle;
}

fieldset.checkbox_container, fieldset.radio_container {
	border: 0;
	margin: 0;
	padding: 0;
}

/* Text fields */
.tl_text {
	width: 100%;
}

.tl_text_2, .tl_text_interval {
	width: 49%;
}

.tl_text_3 {
	width: 32.333%;
}

.tl_text_4 {
	width: 24%;
}

.tl_textarea {
	width: 100%;
}

.tl_text_unit {
	width: 79%;
}

.tl_text_trbl {
	width: 19%;
}

.tl_text, .tl_text_2, .tl_text_3, .tl_text_4, .tl_textarea, .tl_text_unit, .tl_text_trbl, .tl_text_interval {
	height: 30px;
	margin: 3px 0;
	box-sizing: border-box;
	padding: 5px 6px 6px;
	border: 1px solid var(--form-border);
	border-radius: 2px;
	background-color: var(--form-bg);
	-moz-appearance: none;
	-webkit-appearance: none;
}

.tl_text[disabled], .tl_text_2[disabled], .tl_text_3[disabled], .tl_text_4[disabled], .tl_textarea[disabled], .tl_text_unit[disabled], .tl_text_trbl[disabled], .tl_text_interval[disabled] {
	color: var(--form-text-disabled);
	background-color: var(--form-bg-disabled);
	border: 1px solid var(--form-border-disabled);
	cursor: not-allowed;
}

.tl_text[readonly], .tl_text_2[readonly], .tl_text_3[readonly], .tl_text_4[readonly], .tl_textarea[readonly], .tl_text_unit[readonly], .tl_text_trbl[readonly], .tl_text_interval[readonly] {
	background-color: var(--form-bg-disabled);
	border: 1px solid var(--form-border-disabled);
}

.tl_textarea {
	height: 240px;
	padding: 4px 6px;
	line-height: 1.45;
}

.tl_text_2, .tl_text_3, .tl_text_4, .tl_text_unit, .tl_text_trbl, .tl_text_interval {
	margin-right: 1%;
}

.tl_text_2:last-child, .tl_text_3:last-child, .tl_text_4:last-child, .tl_text_trbl:last-child {
	margin-right: 0;
}

.tl_text_field .tl_text_2 {
	width: 49.5%;
}

.tl_imageSize_0 {
	margin-left: 1%;
}

input[type="search"] {
	height: 27px;
	padding-top: 0;
	padding-bottom: 1px;
}

input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}

input[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	height: 14px;
	width: 14px;
	margin-right: 0;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzc3NyI+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMnoiLz48L3N2Zz4=");
}

@-moz-document url-prefix() {
	.tl_text::placeholder, .tl_text_2::placeholder, .tl_text_3::placeholder, .tl_text_4::placeholder, .tl_textarea::placeholder, .tl_text_unit::placeholder, .tl_text_trbl::placeholder, .tl_text_interval::placeholder {
		line-height: 18px;
	}
}

@media not all and (min-resolution: .001dpcm) {
	@supports (-webkit-appearance:none) {
		.tl_text::placeholder, .tl_text_2::placeholder, .tl_text_3::placeholder, .tl_text_4::placeholder, .tl_textarea::placeholder, .tl_text_unit::placeholder, .tl_text_trbl::placeholder, .tl_text_interval::placeholder {
			line-height: 16px;
		}

		input[type="search"] {
			padding-right: 0;
		}

		input[type="search"]::-webkit-search-cancel-button {
			margin: 7px 4px 0 0;
		}
	}
}

@supports (display:-ms-grid) {
	.tl_text, .tl_text_2, .tl_text_3, .tl_text_4, .tl_textarea, .tl_text_unit, .tl_text_trbl, .tl_text_interval {
		padding: 4px 6px 5px;
	}
}

/* Select menus */
select {
	text-transform: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

select::-ms-expand {
	display: none;
}

select[multiple] {
	height: auto;
}

.tl_select, .tl_mselect, .tl_select_column {
	width: 100%;
}

.tl_select_unit {
	width: 20%;
}

.tl_select_interval {
	width: 50%;
}

.tl_select, .tl_mselect, .tl_select_column, .tl_select_unit, .tl_select_interval {
	height: 30px;
	margin: 3px 0;
	box-sizing: border-box;
	border: 1px solid var(--form-border);
	padding: 5px 22px 6px 6px;
	border-radius: 2px;
	background: var(--form-bg) url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDUwMCA1MDAiPjxsaW5lYXJHcmFkaWVudCBpZD0iYSIgZ3JhZGllbnRVbml0cz0idXNlclNwYWNlT25Vc2UiIHgxPSIxMzEuNTIzIiB5MT0iNDIuNjMiIHgyPSIzNjguNDc4IiB5Mj0iMjc5LjU4NCI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjYjNiM2IzIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjOTk5Ii8+PC9saW5lYXJHcmFkaWVudD48cGF0aCBmaWxsPSJ1cmwoI2EpIiBkPSJNMjUwIDM5Ni42NjZjLTEuMTU1IDAtNC4xMS0xLjgzMi03LjExMy02Ljc1bC0xNjkuNi0yNzcuNDU1Yy0yLjUxNy00LjExNC0zLjE5LTYuOTgtMy4yOC04LjMxNC44MjctLjMzIDIuNTY1LS44MTIgNS42MjctLjgxMmgzNDguNzMzYzMuMDYzIDAgNC43OTguNDgyIDUuNjI3LjgxMi0uMDkgMS4zMzQtLjc2NiA0LjItMy4yOCA4LjMxNWwtMTY5LjYgMjc3LjQ1N2MtMy4wMDUgNC45MTctNS45NiA2Ljc1LTcuMTE0IDYuNzV6Ii8+PC9zdmc+") right -16px top 3px no-repeat;
	background-origin: content-box;
	cursor: pointer;
}

.tl_select[disabled], .tl_mselect[disabled], .tl_select_column[disabled], .tl_select_unit[disabled], .tl_select_interval[disabled],
.tl_select[readonly], .tl_mselect[readonly], .tl_select_column[readonly], .tl_select_unit[readonly], .tl_select_interval[readonly] {
	color: var(--form-text-disabled);
	background-color: var(--form-bg-disabled);
	border: 1px solid var(--form-border-disabled);
	cursor: not-allowed;
}

.tl_select[multiple], .tl_mselect[multiple], .tl_select_column[multiple], .tl_select_unit[multiple], .tl_select_interval[multiple] {
	background-image: none;
}

@supports (display:-ms-grid) {
	.tl_select, .tl_mselect, .tl_select_column, .tl_select_unit, .tl_select_interval {
		padding: 5px 18px 5px 2px;
	}
}

/* Checkboxes */
.tl_checkbox {
	margin: 0 1px 0 0;
}

.tl_tree_checkbox {
	margin: 1px 1px 1px 0;
}

.tl_checkbox_single_container {
	height: 16px;
	margin: 14px 0 1px;
}

.tl_checkbox_single_container label {
	margin-left: 4px;
	margin-right: 3px;
	font-weight: 600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.tl_checkbox_single_container label {
		font-weight: 500;
	}
}

/* Grouped checkboxes */
.checkbox_toggler {
	font-weight: 600;
}

.checkbox_toggler_first {
	margin-top: 3px;
	font-weight: 600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.checkbox_toggler, .checkbox_toggler_first {
		font-weight: 500;
	}
}

.checkbox_toggler img, .checkbox_toggler_first img {
	position: relative;
	top: -1px;
	margin-right: 2px;
}

.checkbox_options {
	margin: 0 0 6px 21px !important;
}

.tl_checkbox_container .checkbox_options:last-of-type {
	margin-bottom: 0 !important;
}

/* Radio buttons */
.tl_radio {
	margin: 0 1px 0 0;
}

.tl_tree_radio {
	margin: 1px 1px 1px 0;
}

/* Radio table */
.tl_radio_table {
	margin-top: 3px;
}

.tl_radio_table td {
	padding: 0 24px 0 0;
}

/* Upload fields */
.tl_upload_field {
	margin: 1px 0;
}

/* Submit buttons */
.tl_submit {
	height: 30px;
	padding: 7px 12px;
	border: 1px solid var(--form-border);
	border-radius: 2px;
	box-sizing: border-box;
	cursor: pointer;
	background: var(--form-button);
	transition: background .2s ease;
}

.tl_submit:hover {
	color: inherit;
	background-color: var(--form-button-hover);
}

.tl_submit:active {
	color: var(--form-button-active);
}

.tl_submit:disabled {
	color: var(--gray);
	background: var(--form-button-disabled) !important;
	cursor: not-allowed;
}

.tl_panel .tl_submit, .tl_version_panel .tl_submit, .tl_formbody_submit .tl_submit {
	background: var(--form-bg);
}

.tl_panel .tl_submit:hover, .tl_version_panel .tl_submit:hover, .tl_formbody_submit .tl_submit:hover {
	background: var(--form-bg-hover);
}

a.tl_submit {
	display: inline-block;
}

/* Split buttons */
.split-button {
	display: inline-block;
	position: relative;
	z-index: 1;
}

.split-button ul, .split-button li {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* Placeholders */
::-moz-placeholder {
	padding-top: 1px;
}

::-webkit-input-placeholder {
	padding-top: 1px;
}

/* Floats */
.clr {
	clear: both;
	width: calc(100% - 30px);
}

.clr:not(.widget) {
	width: 100%;
}

.clr:before {
	content: "";
	display: table;
}

.w25, .w33, .w50, .w66, .w75 {
	width: calc(50% - 30px);
	float: left;
	min-height: 80px;
}

.nogrid .w25, .nogrid .w33, .nogrid .w50, .nogrid .w66, .nogrid .w75 {
	float: none;
}

.long {
	width: calc(100% - 30px); /* see #6320 */
}

.wizard > a {
	position: relative;
	top: -2px;
	vertical-align: middle;
}

.wizard > .image-button {
	padding: 0;
	border: 0;
	background: none;
	vertical-align: middle;
}

.wizard .tl_text, .wizard .tl_select, .wizard .tl_image_size {
	width: calc(100% - 24px);
}

.wizard .tl_text_2 {
	width: 45%;
}

.wizard .tl_image_size {
	display: inline-block;
}

.wizard img {
	margin-left: 4px;
}

.wizard h3 img {
	margin-left: 0;
}

.long .tl_text, .long .tl_select {
	width: 100%;
}

.m12 {
	margin: 0 15px;
	padding: 18px 0 16px;
}

.nogrid .m12 {
	padding: 0;
}

.cbx {
	min-height: 46px;
}

.cbx.m12 {
	min-height: 80px;
	box-sizing: border-box;
}

.nogrid .cbx {
	min-height: 32px;
}

.subpal {
	clear: both;
}

.inline div {
	display: inline;
}

.autoheight {
	height: auto;
}

/* Tips */
.tl_tip {
	height: 15px;
	overflow: hidden;
	cursor: help;
}

.tip {
	position: relative;
	max-width: 320px;
	padding: 6px 9px;
	border-radius: 2px;
	background: var(--invert-bg);
	z-index: 99;
}

.tip div {
	line-height: 1.3;
}

.tip div, .tip a, .tip span {
	color: var(--invert-text);
}

.tip:before {
	content: "";
	height: 6px;
	position: absolute;
	top: -13px;
	left: 9px;
	border-left: 7px solid transparent;
	border-right: 7px solid transparent;
	border-bottom: 7px solid var(--invert-bg);
}

.tip--rtl:before {
	left: auto;
	right: 9px;
}

/* Row highlighting */
.hover-div:hover, .hover-row:hover td, .hover-div:hover .limit_toggler, .hover-row:hover .limit_toggler {
	background-color: var(--hover-row) !important;
}

/* Badge */
.badge-title {
	float: right;
	margin-left: 8px;
	margin-top: -8px;
	border-radius: 8px;
	padding: 2px 5px;
	background: var(--invert-bg);
	color: var(--invert-text);
	font-size: .75rem;
	font-weight: 600;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
	.badge-title {
		font-weight: 500;
	}
}

/* Large screens */
@media (min-width: 1280px) {
	.w25 {
		width: calc(25% - 30px);
	}

	.w33 {
		width: calc(33.3333% - 30px);
	}

	.w66 {
		width: calc(66.6666% - 30px);
	}

	.w75 {
		width: calc(75% - 30px);
	}

	#sbtog {
		display: none;
	}

	.split-button ul {
		display: inline-flex;
		clip: initial;
		height: auto;
		margin: 0 0 0 -4px;
		overflow: initial;
		position: initial;
		width: auto;
	}

	.split-button li {
		margin-left: 4px;
	}
}

/* Split button */
@media (max-width: 1279px) {
	.split-button {
		display: inline-flex;
	}

	.split-button ul {
		position: absolute;
		right: 0;
		bottom: 20px;
		min-width: 100%;
		background: var(--form-bg);
		border: 1px solid var(--form-border);
		border-radius: 2px;
		box-sizing: border-box;
		padding: 3px 0;
		margin-bottom: 1em;
	}

	.split-button ul button {
		border: 0;
		width: 100%;
		text-align: left;
		white-space: nowrap;
	}

	.split-button ul .tl_submit {
		margin-top: 0;
		margin-bottom: 0;
		background: var(--form-bg);
	}

	.split-button ul .tl_submit:hover {
		background: var(--form-button-hover);
	}

	.split-button ul:before {
		content: "";
		display: block;
		height: 0;
		width: 0;
		position: absolute;
		right: 4px;
		bottom: -12px;
		z-index: 89;
		border: 6px inset transparent;
		border-top: 6px solid var(--form-bg);
	}

	.split-button ul:after {
		content: "";
		display: block;
		height: 0;
		width: 0;
		position: absolute;
		right: 3px;
		bottom: -14px;
		z-index: 88;
		border: 7px inset transparent;
		border-top: 7px solid var(--form-border);
	}

	.split-button > button[type="submit"] {
		position: relative;
		border-radius: 2px 0 0 2px;
	}

	.split-button > button[type="button"] {
		height: 30px;
		margin: 2px 0;
		padding: 7px 4px;
		background: var(--form-bg);
		border: 1px solid var(--form-border);
		border-left: 0;
		border-radius: 0 2px 2px 0;
		box-sizing: border-box;
		transition: background .2s ease;
	}

	.split-button > button[type="button"].active, .split-button > button[type="button"]:hover {
		background: var(--form-button-hover);
	}

	.split-button > button[type="button"]:focus {
		outline: none;
	}
}

/* Handheld */
@media (max-width: 767px) {
	.w25, .w33, .w50, .w66, .w75 {
		float: none;
		width: calc(100% - 30px);
	}

	.m12 {
		padding-top: 0;
		padding-bottom: 0;
	}

	.cbx, .cbx.m12 {
		min-height: auto;
	}

	.tip {
		max-width: 80vw;
	}

	.tl_checkbox_container .tl_checkbox {
		margin-top: 1px;
		margin-bottom: 1px;
	}
}
